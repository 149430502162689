import {doViewsLogin} from "@/service/views/common";

export default [
    {
        path:"/",
        redirect:"/admin",
    },
    {
        path: '*',
        component: ()=> import('@/views/Error.vue'),
        meta: {title: '错误'},
    }
]
