import {isDev} from "@/common/other";

export const site_config = {
    title:process.env.VUE_APP_TITLE,
    cookie_prefix:process.env.VUE_APP_COOKIE_PREFIX || 'cookie',
}
export const ext_config = {
    file:".pdf,.ppt,.doc,.docx,.word,.xlxs,.xls",
    image:".jpg,.jpeg,.png,.gif,.svg,.tiff",
    video:".mp4,.mov,.ogg",
}

export const host_config = {
    api:isDev() ? process.env.VUE_APP_API_HOST : process.env.VUE_APP_API_HOST,
}
