import {getAdminToken} from "@/service/admin/common.js";

export default [
	{
		path: '/admin/auth/login',
		component: ()=> import('@/views/admin/auth/Login.vue'),
		meta: {title: '登录'},
	},
	{
		path:"/admin",
		component:()=> import('@/layout/Admin.vue'),
		children:[
			{
				path: '/admin',
				component: ()=> import('@/views/admin/dashboard/Index.vue'),
				meta: {title: '控制台'},
			},
			{
				path: '/admin/admin',
				component: ()=> import('@/views/admin/admin/Index.vue'),
				meta: {title: '管理员'},
			},
			{
				path: '/admin/article',
				component: ()=> import('@/views/admin/article/Index.vue'),
				meta: {title: '文章'},
			},
			{
				path: '/admin/adv',
				component: ()=> import('@/views/admin/adv/Index.vue'),
				meta: {title: '广告位'},
			},
			{
				path: '/admin/config',
				component: ()=> import('@/views/admin/config/Index.vue'),
				meta: {title: '系统配置'},
			},
			{
				path: '/admin/product/category',
				component: ()=> import('@/views/admin/product/category/Index.vue'),
				meta: {title: '产品分类'},
			},
			{
				path: '/admin/product',
				component: ()=> import('@/views/admin/product/index/Index.vue'),
				meta: {title: '产品列表'},
			},
			{
				path: '/admin/store',
				component: ()=> import('@/views/admin/store/index/Index.vue'),
				meta: {title: '体验店列表'},
			},
			{
				path: '/admin/store/book',
				component: ()=> import('@/views/admin/store/book/Index.vue'),
				meta: {title: '预约列表'},
			},
			{
				path: '/admin/agent',
				component: ()=> import('@/views/admin/agent/index/Index.vue'),
				meta: {title: '经销商列表'},
			},
			{
				path: '/admin/agent/book',
				component: ()=> import('@/views/admin/agent/book/Index.vue'),
				meta: {title: '联系列表'},
			},
			{
				path: '/admin/file',
				component: ()=> import('@/views/admin/file/Index.vue'),
				meta: {title: '文件管理'},
			},
			{
				path: '/admin/video',
				component: ()=> import('@/views/admin/video/Index.vue'),
				meta: {title: '视频管理'},
			},
			{
				path: '/admin/user',
				component: ()=> import('@/views/admin/user/Index.vue'),
				meta: {title: '用户管理'},
			},
			{
				path: '/admin/user/consult',
				component: ()=> import('@/views/admin/user/Consult.vue'),
				meta: {title: '售前售后'},
			},
			{
				path: '/admin/*',
				name: 'Error',
				component: ()=> import('@/views/admin/Error.vue'),
				meta: {title: '错误'},
			}
		],
		beforeEnter:(to,from,next)=>{
			if(!getAdminToken()){
				next('/admin/auth/login')
			}else{
				next();
			}
		}
	}
]
