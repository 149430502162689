import {request} from '@/common/request/admin.js'

export const site = {
	upload(data){
		return request.post('/backend/upload/index',data,{
			headers: {
				'Content-Type' : 'multipart/form-data'
			},
			timeout:60*20*1000
		})
	},
	cos(){
		return request.get('/backend/upload/cos')
	}
}

export const attachment = {
	all(params){
		return request.get('/backend/attachment/all',{params})
	},
	create(data){
		return request.post('/backend/attachment/create',data)
	}
}

export const auth = {
	login(data){
		return request.post('/backend/auth/login',data)
	},
	logout(){
		return request.post('/backend/auth/logout')
	},
	getLoginInfo(){
		return request.post('/backend/auth/getLoginInfo')
	}
}

export const dashboard = {
	balance(params){
		return request.get('/backend/dashboard/balance',{params})
	},
	user(params){
		return request.get('/backend/dashboard/user',{params})
	},
}

export const config = {
	get(params){
		return request.get('/backend/config/get',{params})
	},
	create(data){
		return request.post('/backend/config/create',data)
	}
}

export const admin = {
	get(params){
		return request.get('/backend/admin/get',{params})
	},
	all(params){
		return request.get('/backend/admin/all',{params})
	},
	create(data){
		return request.post('/backend/admin/create',data)
	},
	delete(data){
		return request.post('/backend/admin/delete',data)
	},
}

export const article = {
	get(params){
		return request.get('/backend/article/get',{params})
	},
	all(params){
		return request.get('/backend/article/all',{params})
	},
	create(data){
		return request.post('/backend/article/create',data)
	},
	delete(data){
		return request.post('/backend/article/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/article/changeOrder',data)
	},
}

export const adv = {
	get(params){
		return request.get('/backend/adv/get',{params})
	},
	all(params){
		return request.get('/backend/adv/all',{params})
	},
	create(data){
		return request.post('/backend/adv/create',data)
	},
	delete(data){
		return request.post('/backend/adv/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/adv/changeOrder',data)
	},
}

export const category = {
	get(params){
		return request.get('/backend/category/get',{params})
	},
	all(params){
		return request.get('/backend/category/all',{params})
	},
	create(data){
		return request.post('/backend/category/create',data)
	},
	delete(data){
		return request.post('/backend/category/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/category/changeOrder',data)
	},
}

export const product = {
	get(params){
		return request.get('/backend/product/get',{params})
	},
	all(params){
		return request.get('/backend/product/all',{params})
	},
	create(data){
		return request.post('/backend/product/create',data)
	},
	delete(data){
		return request.post('/backend/product/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/product/changeOrder',data)
	},
	import(data){
		return request.post('/backend/product/import',data)
	}
}

export const product_attachment = {
	get(params){
		return request.get('/backend/product_attachment/get',{params})
	},
	all(params){
		return request.get('/backend/product_attachment/all',{params})
	},
	create(data){
		return request.post('/backend/product_attachment/create',data)
	},
	delete(data){
		return request.post('/backend/product_attachment/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/product_attachment/changeOrder',data)
	},
}

export const store = {
	get(params){
		return request.get('/backend/store/get',{params})
	},
	all(params){
		return request.get('/backend/store/all',{params})
	},
	create(data){
		return request.post('/backend/store/create',data)
	},
	delete(data){
		return request.post('/backend/store/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/store/changeOrder',data)
	},
	import(data){
		return request.post('/backend/store/import',data)
	}
}

export const store_book = {
	get(params){
		return request.get('/backend/store_book/get',{params})
	},
	all(params){
		return request.get('/backend/store_book/all',{params})
	},
	delete(data){
		return request.post('/backend/store_book/delete',data)
	},
}

export const agent = {
	get(params){
		return request.get('/backend/agent/get',{params})
	},
	all(params){
		return request.get('/backend/agent/all',{params})
	},
	create(data){
		return request.post('/backend/agent/create',data)
	},
	delete(data){
		return request.post('/backend/agent/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/agent/changeOrder',data)
	},
	import(data){
		return request.post('/backend/agent/import',data)
	}
}

export const agent_book = {
	get(params){
		return request.get('/backend/agent_book/get',{params})
	},
	all(params){
		return request.get('/backend/agent_book/all',{params})
	},
	delete(data){
		return request.post('/backend/agent_book/delete',data)
	},
}

export const file = {
	get(params){
		return request.get('/backend/file/get',{params})
	},
	all(params){
		return request.get('/backend/file/all',{params})
	},
	create(data){
		return request.post('/backend/file/create',data)
	},
	delete(data){
		return request.post('/backend/file/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/file/changeOrder',data)
	}
}

export const video = {
	get(params){
		return request.get('/backend/video/get',{params})
	},
	all(params){
		return request.get('/backend/video/all',{params})
	},
	create(data){
		return request.post('/backend/video/create',data)
	},
	delete(data){
		return request.post('/backend/video/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/video/changeOrder',data)
	}
}

export const user = {
	get(params){
		return request.get('/backend/user/get',{params})
	},
	all(params){
		return request.get('/backend/user/all',{params})
	}
}

export const user_event = {
	all(params){
		return request.get('/backend/user_event/all',{params})
	}
}

export const user_score = {
	all(params){
		return request.get('/backend/user_score/all',{params})
	}
}

export const consult = {
	all(params){
		return request.get('/backend/consult/all',{params})
	}
}
