/**
 * 是否假值
 * @param o
 * @returns {boolean}
 */
export const isFalse = (o)=>{
	return (o === '' || o === undefined || o === null || o === 'null' || o === 'undefined' || o === 0 || o === false || isNaN(o));
}

/**
 * 是否真值
 */
export const isTrue = (o)=>{
	return !!isFalse(o)
}

/**
 * 是否移动环境
 * @param ua
 * @returns {boolean}
 */
export const isMobile = (ua)=>{
	ua = navigator?.userAgent || '';
	return /mobile|android|iphone|ipad|phone/i.test(ua.toLowerCase())
}

/**
 * 是否PAD
 * @param ua
 * @returns {boolean}
 */
export const isIpad = (ua)=>{
	ua = navigator?.userAgent || '';
	return /ipad/i.test(ua.toLowerCase())
}

/**
 * 是否pc
 * @param ua
 * @returns {boolean}
 */
export const isPC = (ua)=>{
	ua = navigator?.userAgent || '';
	var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
	var flag = true;
	for (let v = 0; v < Agents.length; v++) {
		if (ua.indexOf(Agents[v]) > 0) {
			flag = false;
			break;
		}
	}
	return flag;
}

/**
 * 是否微信
 * @param ua
 * @returns {boolean}
 */
export const isWeiXin = (ua)=>{
	ua = navigator?.userAgent || '';
	return /micromessenger/i.test(ua.toLowerCase())
}

/**
 * 是否IE
 * @returns {boolean}
 */
export const isIE = () =>{
	if(process.server){return false}
	return !!window.ActiveXObject || "ActiveXObject" in window;
}

/**
 * 是否IOS
 * @param ua
 * @returns {boolean}
 */
export const isIos = (ua)=>{
	ua = navigator?.userAgent || '';
	if (ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1) {//安卓手机
		return false
	} else if (ua.indexOf('iPhone') > -1) {//苹果手机
		return true
	} else if (ua.indexOf('iPad') > -1) {//iPad
		return false
	} else if (ua.indexOf('Windows Phone') > -1) {//winphone手机
		return false
	} else {
		return false
	}
}

/**
 * 常见字符校验
 * @param str
 * @param type
 * @returns {boolean}
 */
export const checkString = (str,type)=>{
	switch (type) {
		case 'phone':   //手机号码
			return /^1[1-9]{10}$/.test(str);
		case 'tel':     //座机
			return /^(0\d{2,3}-\d{7,8})(-\d{1,4})?$/.test(str);
		case 'card':    //身份证
			// 1 "验证通过!", 0 //校验不通过 // id为身份证号码
			var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
			//号码规则校验
			if (!format.test(str)) {
				return false;
			}
			//区位码校验
			//出生年月日校验  前正则限制起始年份为1900;
			var year = str.substr(6, 4),//身份证年
				month = str.substr(10, 2),//身份证月
				date = str.substr(12, 2),//身份证日
				time = Date.parse(month + '-' + date + '-' + year),//身份证日期时间戳date
				now_time = Date.parse(new Date()),//当前时间戳
				dates = (new Date(year, month, 0)).getDate();//身份证当月天数
			if (time > now_time || date > dates) {
				return false;
			}
			//校验码判断
			var c = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];  //系数
			var b = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']; //校验码对照表
			var id_array = str.split("");
			var sum = 0;
			for (var k = 0; k < 17; k++) {
				sum += parseInt(id_array[k]) * parseInt(c[k]);
			}
			if (id_array[17].toUpperCase() !== b[sum % 11].toUpperCase()) {
				return false;
			}
			return true;
		case 'pwd':     //密码以字母开头，长度在6~18之间，只能包含字母、数字和下划线
			return /^[a-zA-Z]\w{5,17}$/.test(str)
		case 'postal':  //邮政编码
			return /[1-9]\d{5}(?!\d)/.test(str);
		case 'QQ':      //QQ号
			return /^[1-9][0-9]{4,10}$/.test(str);
		case 'email':   //邮箱
			return /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(str);
		case 'money':   //金额(小数点2位)
			return /^\d*(?:\.\d{0,2})?$/.test(str);
		case 'URL':     //网址
			return /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/.test(str)
		case 'IP':      //IP
			return /((?:(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d)\\.){3}(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d))/.test(str);
		case 'date':    //日期时间
			return /^(\d{4})\-(\d{2})\-(\d{2}) (\d{2})(?:\:\d{2}|:(\d{2}):(\d{2}))$/.test(str) || /^(\d{4})\-(\d{2})\-(\d{2})$/.test(str)
		case 'number':  //数字
			return /^[0-9]$/.test(str);
		case 'english': //英文
			return /^[a-zA-Z]+$/.test(str);
		case 'chinese': //中文
			return /^[\u4E00-\u9FA5]+$/.test(str);
		case 'lower':   //小写
			return /^[a-z]+$/.test(str);
		case 'upper':   //大写
			return /^[A-Z]+$/.test(str);
		case 'HTML':    //HTML标记
			return /<("[^"]*"|'[^']*'|[^'">])*>/.test(str);
		default:
			return true;
	}
}

/**
 * 严格的身份证校验
 * @param sId
 * @returns {boolean}
 */
export const isCardID = (sId)=>{
	if (!/(^\d{15}$)|(^\d{17}(\d|X|x)$)/.test(sId)) {
		//'你输入的身份证长度或格式错误'
		return false
	}
	//身份证城市
	var aCity = {
		11: "北京",
		12: "天津",
		13: "河北",
		14: "山西",
		15: "内蒙古",
		21: "辽宁",
		22: "吉林",
		23: "黑龙江",
		31: "上海",
		32: "江苏",
		33: "浙江",
		34: "安徽",
		35: "福建",
		36: "江西",
		37: "山东",
		41: "河南",
		42: "湖北",
		43: "湖南",
		44: "广东",
		45: "广西",
		46: "海南",
		50: "重庆",
		51: "四川",
		52: "贵州",
		53: "云南",
		54: "西藏",
		61: "陕西",
		62: "甘肃",
		63: "青海",
		64: "宁夏",
		65: "新疆",
		71: "台湾",
		81: "香港",
		82: "澳门",
		91: "国外"
	};
	if (!aCity[parseInt(sId.substr(0, 2))]) {
		// '你的身份证地区非法'
		return false
	}

	// 出生日期验证
	var sBirthday = (sId.substr(6, 4) + "-" + Number(sId.substr(10, 2)) + "-" + Number(sId.substr(12, 2))).replace(/-/g, "/"),
		d = new Date(sBirthday)
	if (sBirthday !== (d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate())) {
		//'身份证上的出生日期非法'
		return false
	}

	// 身份证号码校验
	var sum = 0,
		weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2],
		codes = "10X98765432"
	for (var i = 0; i < sId.length - 1; i++) {
		sum += sId[i] * weights[i];
	}
	var last = codes[sum % 11]; //计算出来的最后一位身份证号码
	return sId[sId.length - 1] === last;
}

/**
 * 浏览器类型
 * @param userAgent
 * @returns {string}
 */
export const browser = (userAgent)=>{
	var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
	var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
	var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
	var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
	var isSafari = userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1; //判断是否Safari浏览器
	var isChrome = userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

	if (isIE) {
		var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
		reIE.test(userAgent);
		var fIEVersion = parseFloat(RegExp["$1"]);
		if (fIEVersion === 7) {
			return "IE7";
		} else if (fIEVersion === 8) {
			return "IE8";
		} else if (fIEVersion === 9) {
			return "IE9";
		} else if (fIEVersion === 10) {
			return "IE10";
		} else if (fIEVersion === 11) {
			return "IE11";
		} else {
			return "IE";
		}
	}
	if (isOpera) {
		return "Opera";
	}
	if (isEdge) {
		return "Edge";
	}
	if (isFF) {
		return "FF";
	}
	if (isSafari) {
		return "Safari";
	}
	if (isChrome) {
		return "Chrome";
	}
}
