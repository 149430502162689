<template>
	<main>
		<div class="upload">
			<el-input class="upload-box" v-model="modelValue" clearable>
				<template #append>
					<div class="btn-box">
						<div class="upload-btn">
							<el-button class="btn" icon="Plus" type="" :loading="upload_loading" >上传</el-button>
							<input class="input" type="file" :accept="accept_list" @change="chooseFile"/>
						</div>
						<div class="choose-btn">
							<el-button class="btn" icon="Aim" type="primary"  @click="show_attachment = true">选择</el-button>
						</div>
					</div>
				</template>
			</el-input>
			<div class="tip" v-if="tip">{{tip}}</div>
		</div>
		<div class="preview" v-if="value_array.length > 0">
			<div class="item" v-for="(item,index) in value_array" :key="index">
				<img class="image" v-if="isImage(item)" :src="item" alt="">
				<a v-else :href="item" class="link">
					<span class="icon"><i class="t">{{fileName(item)}}</i></span>
				</a>
				<div class="act">
					<div class="wrap">
						<i class="icon el-icon-view" @click="preview(item)"></i>
						<i class="icon el-icon-delete" @click="removeFile(index)"></i>
					</div>
				</div>
			</div>
		</div>
		<component-attachment v-if="show_attachment" :accept="accept_list" @close="show_attachment = false" @choose="choose"/>
		<component-cropper v-if="cropper_visible" :size="size" ref="cropperRef" @success="cropperSuccess" @error="cropperError"/>
		<component-preview v-if="preview_visible" :images="preview_image" @closeViewer="closeViewer"/>
	</main>
</template>

<script >
import ComponentCropper from "@/components/admin/form/upload/Cropper.vue"
import ComponentPreview from "@/components/admin/form/upload/Preview.vue"
import ComponentAttachment from "@/components/admin/form/upload/Attachment.vue"
import {attachment, site} from "@/api/admin";
import {ext_config} from "@/config/index.js";
import {inArray} from "@/common/array";
import COS from "cos-js-sdk-v5";
import {createUUID} from "@/common/string";
import moment from "moment";

export default {
	components:{ComponentCropper,ComponentPreview,ComponentAttachment},
	props:{
		value:"",
		limit:{
			type:Number,
			default:1
		},
		accept:{
			type:String,
			default:'*'
		},
		size:{
			type:String,
			default:""
		},
		tip:{
			type:String,
			default:""
		}
	},
	data(){
		return {
			show_attachment:false,

			upload_loading:false,
			upload_disable:false,

			cropper_visible:false,
			preview_visible:false,
			preview_image:"",

			defaultImage: '',

			ext:"",
			cos_config:{}
		}
	},
	computed:{
		modelValue:{
			get(){
				return this.value
			},
			set(value){
				this.$emit('input',value)
			}
		},
		value_array(){
			return this.value ? this.value.split(',') : []
		},
		accept_list(){
			var accept_list;
			if(!this.accept || this.accept === '*'){
				accept_list = "*"
			}else if(inArray(['file','image','video'],this.accept)){
				accept_list = ext_config[this.accept];
			}else{
				accept_list = this.accept;
			}
			return accept_list;
		}
	},
	methods:{
		fileName(file){
			var index = file.lastIndexOf(".");
			var ext = file.substring(index+1);
			return ext.substring(0,5)
		},
		cropperSuccess(file){
			this.cropper_file = file;
			this.cropper_visible = false;
		},
		cropperError(message){
			this.$message.error(message);
			this.cropper_visible = false;
		},
		checkFileType(name){
			var index= name.lastIndexOf(".");
			var ext = name.substring(index+1);
			this.ext = ext;
			var accept_list = this.accept_list.split(',');
			return inArray(accept_list,'*') || inArray(accept_list,'.'+ext);
		},
		chooseFile(event){
			var file = event.target.files[0];
			document.querySelector('.input').value = "";

			if(!this.checkFileType(file.name)){
				this.$message.error('文件格式不正确');
				return  false;
			}

			this.cropper_file = '';
			if(!this.size || !this.canCropper(file.name)){
				this.cropper_file = file;
				this.doUpload();
			}else{
				this.cropper_visible = true;
				this.$nextTick(()=>{
					this.$refs['cropperRef'].init(file);

					clearInterval(this.timer);
					this.timer = setInterval(()=>{
						if(this.cropper_file){
							clearInterval(this.timer);
							this.doUpload()
						}
					},300);

					this.$once('hook:beforeDestroy',()=>{
						clearInterval(this.timer);
					})
				})
			}
			return false;
		},
		cosInit(){
			return new Promise((resolve, reject)=>{
				site.cos().then((res)=>{
					this.cos_config = res.config;
					const cos = new COS({
						// getAuthorization 必选参数
						getAuthorization:  (options, callback)=>{
							callback({
								TmpSecretId: this.cos_config.credentials.tmpSecretId,
								TmpSecretKey:  this.cos_config.credentials.tmpSecretKey,
								SecurityToken:  this.cos_config.credentials.sessionToken,
								// 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
								StartTime:  this.cos_config.startTime, // 时间戳，单位秒，如：1580000000
								ExpiredTime:  this.cos_config.expiredTime, // 时间戳，单位秒，如：1580000000
							});
						}
					})
					resolve(cos);
				})
			})
		},
		doUpload(){
			this.cosInit().then((cos)=>{
				const file_name = createUUID()+"."+this.ext
				cos.uploadFile({
					Bucket:  this.cos_config.ext.bucket, /* 填入您自己的存储桶，必须字段 */
					Region: this.cos_config.ext.region,  /* 存储桶所在地域，例如ap-beijing，必须字段 */
					Key: this.cos_config.ext.dir+file_name,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
					Body: this.cropper_file, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
					SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */
					onTaskReady: (taskId)=>{                   /* 非必须 */
					},
					onProgress:  (progressData)=>{           /* 非必须 */
					},
					onFileFinish:  (err, data, options)=>{   /* 非必须 */
						const url = this.cos_config.ext.host+'/'+options.Key;
						let list = this.value ? this.value.split(',') : [];
						if(this.limit > 1){
							list.push(url);
						}else{
							list = [url]
						}
						this.change(list.join(','));
						this.createAttachment(url);
					},
				}, (err, data)=>{
					console.log(err || data);
				});
			})
		},
		createAttachment(url){
			const report = (image)=>{
				const data = {
					url:url,
					image_width:image.width,
					image_height:image.height,
					image_type:this.ext,
					file_name:this.cropper_file.name,
					mimetype:this.cropper_file.type,
					file_size:this.cropper_file.size,
				}
				attachment.create(data).then()
			}
			console.log(this.cropper_file.name);
			if(this.canCropper(this.cropper_file.name)){
				this.getImageSize(this.cropper_file).then((res)=>{
					report(res)
				})
			}else{
				report({
					width:0,
					height:0
				})
			}
		},
		getImageSize(file){
			return new Promise((resolve, reject)=>{
				this.fileToBlob(file).then((blob)=>{
					var img = new Image();
					img.src = blob;
					img.onload =()=>{
						resolve({
							width:img.width,
							height:img.height
						})
					}
				}).catch((res)=>{
					resolve({
						width:0,
						height:0
					})
				})
			})
		},
		fileToBlob(file){
			return new Promise((resolve,reject)=>{
				var reader = new FileReader();
				var blob = null;
				reader.onload = (e) => {
					if (typeof e.target.result === 'object') {
						blob = window.URL.createObjectURL(new Blob([e.target.result]));
					} else {
						blob = e.target.result
					}
					resolve(blob)
				}
				reader.readAsArrayBuffer(file);
			})
		},
		doUpload_bak(){
			this.upload_loading = true;
			var formData = new FormData();
			formData.append('file',this.cropper_file);
			site.upload(formData).then((res)=>{
				let list = this.value ? this.value.split(',') : [];
				if(this.limit > 1){
					list.push(res.url);
				}else{
					list = [res.url]
				}
				this.change(list.join(','))
			}).finally((res)=>{
				this.upload_loading = false;
			})
		},
		closeViewer() {
			this.preview_visible = false;
			this.preview_image = "";
		},
		preview(url) {
			if(this.isImage(url)){
				this.preview_image = [url];
				this.preview_visible = true;
			}else{
				window.open(url,'_blank')
			}
		},
		isImage(url){
			if(!url){return  false;}
			url = url.split('?')[0];
			var index= url.lastIndexOf(".");
			var ext = url.substr(index+1);
			var type = ext_config.image;
			return type.indexOf(ext.toLowerCase()) > -1;
		},

		canCropper(url){
			var index= url.lastIndexOf(".");
			var ext = url.substr(index+1);
			var type = ['png', 'jpg', 'jpeg'];
			return type.indexOf(ext.toLowerCase()) > -1;
		},
		removeFile(index,url){
			const list = this.value ? this.value.split(',') : [];
			list.splice(index,1);
			this.change(list.join(','));
		},
		choose(url){
			this.show_attachment = false;
			let list = this.value ? this.value.split(',') : [];
			if(this.limit > 1){
				list.push(url);
			}else{
				list = [url]
			}
			this.change(list.join(','));
		},
		change(list){
			this.$emit('input',list);
		},
	}
}
</script>

<style lang="scss" scoped>
.tip{font-size: 12px;color:#666;line-height: 1.5;}
.upload-box{
	::v-deep{
		.el-input-group__append{padding: 0;
			.el-button{margin: 0;}
			.el-button--primary{background: #66b1ff;color:#fff;}
		}
	}
	.btn-box{display: flex;height:100%;
		.upload-btn{position: relative}
		.input{position: absolute;top:0;left:0;width: 100%;height:100%;opacity: 0;
		}
	}
}
.preview{display: flex;flex-wrap: wrap;
	.item{width:120px;height:100px;border: 1px solid #ccc;border-radius: 5px;position: relative;margin-right: 10px;margin-top: 10px;display: flex;align-items: center;justify-content: center;
		.image{display: block;width:90%;height:90%;object-position:center center;object-fit: contain;}
		.link{display: block;width: 100%;height:100%;
			.icon{display: block; width: 88px;height:90px;margin: 5px auto;position: relative;background: url("~@/assets/admin/image/file.png") no-repeat center center;background-size: 100% 100%;
				.t{position: absolute;top:40px;left:0;width: 70px;text-align: center; height:40px;line-height: 40px;font-size: 18px;font-weight: bold;color:#333;text-transform: uppercase;}
			}
		}
		.act{position: absolute;top:0;left:0;width: 100%;height:100%;display: flex;align-items: center;justify-content: center;background: rgba(0,0,0,.5);opacity: 0;transition: all .3s ease;
			.icon{width: 22px;height:22px;margin-right: 15px;color:#fff;font-size: 20px;cursor: pointer;}
			.icon:nth-last-child(1){margin-right: 0;}
		}
	}
	.item:hover{
		.act{opacity: 1;transition: all .3s ease}
	}
}
</style>

