<template>
	<main>
		<div class="wrap">
			<el-select v-model="type" placeholder="请选择">
				<el-option v-for="(item,index) in type_list" :key="index" :label="item.title" :value="item.id"></el-option>
			</el-select>
			<el-autocomplete class="in" v-model="modelValue" value-key="title" clearable :fetch-suggestions="querySearchAsync" :placeholder="type === 1 ? 'https://' : '输入关键词搜索相关数据'" @select="handleSelect"></el-autocomplete>
		</div>
	</main>
</template>

<script >
import {article,product} from "@/api/admin.js";

export default {
	props:{
		value:"",
	},
	data(){
		return {
			type_list:[{id:1,title:"自定义"},{id:2,title:"文章"},{id:3,title:"产品"}],
			type:1,
		}
	},
	computed:{
		modelValue:{
			get(){
				return this.value
			},
			set(value){
				this.$emit('input',value)
			}
		}
	},
	watch:{
		type(){
			this.$emit('input','')
		}
	},
	methods:{
		handleSelect(item){
			let link = "";
			switch (this.type){
				case 2:
					link = "/pages/article/detail?article_id="+item.id
					break;
				case 3:
					link = "/pages/product/detail?product_id="+item.id
					break;
				default:
					break;
			}
			this.$emit('input',link)
		},
		querySearchAsync(queryString,callback){
			switch (this.type) {
				case 2:
					this.getArticle(queryString).then((list) => {
						callback(list)
					});
					break;
				case 3:
					this.getProduct(queryString).then((list) => {
						callback(list)
					});
					break;
				default:
					callback([]);
					break;
			}
		},
		getArticle(queryString){
			return new Promise((resolve, reject)=>{
				article.all({
					title:queryString,
					limit:10,
				}).then((res)=>{
					resolve(res.list)
				})
			})
		},
		getProduct(queryString){
			return new Promise((resolve, reject)=>{
				product.all({
					title:queryString,
					limit:10,
				}).then((res)=>{
					resolve(res.list)
				})
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.wrap{display: flex;align-items: center;
	::v-deep{
		.in{flex: 1;margin-left: 7px;}
	}
}
</style>

