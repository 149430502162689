import store from "@/store";
import moment from "moment";

export const getAdminToken = ()=>{
	return store.getters['admin/getToken']
}

export const timeToString = (time)=>{
	return time ? moment.unix(time).format('YYYY-MM-DD HH:mm:ss') : '';
}

export const stringToTime = (time)=>{
	return time ? moment(time).unix() : null;
}


